.highlight pre { background-color: var(--code-background-color) }

/* Felipe's edits to the Pygments Monokai theme. */
/* commented rules are the same as in Monokai. */

html:root {
  --code-text: var(--text-color-more-contrast);
  --code-gray: #8c929c;
  --code-red: #ff8c9a;
  --code-purple: #e38eff;
  --code-blue: #63b9ff;
  --code-green: #98c77c;
  --code-orange: #ffc27d;
  --code-cyan: #78e1f5;
}

/* RUBY */
.highlight .n { color: var(--code-text) } /* normal - was #f8f8f2 */
.highlight .c1 { color: var(--code-gray) } /* comment - or green #68a865 */
.highlight .k { color: var(--code-red) } /* major syntax */
.highlight .o { color: var(--code-red) } /* nonalphanumeric syntax */
.highlight .nb { color: var(--code-red) } /* minor syntax */
.highlight .kp { color: var(--code-red) } /* more minor syntax */
.highlight .mi { color: var(--code-red) } /* number */
.highlight .p { color: var(--code-text) } /* parentheses, braces, punctuation */
.highlight .no { color: var(--code-blue) } /* class */
.highlight .nc { color: var(--code-blue) } /* class definition */
.highlight .nn { color: var(--code-blue) } /* module definition - or dark blue #2c83d4 */
.highlight .nf { color: var(--code-orange) } /* method */
.highlight .vi { color: var(--code-text) } /* @ variables - or brown #c28165 */
.highlight .ss { color: var(--code-cyan) } /* keyword argument */

.highlight .s { color: var(--code-green) } /* string */
.highlight .s1 { color: var(--code-green) } /* string: single quotes */
.highlight .s2 { color: var(--code-green) } /* string: double quotes */
.highlight .sh { color: var(--code-green) } /* string: heredocs */
.highlight .sx { color: var(--code-green) } /* %-delimited string/array/etc. */
.highlight .si { color: var(--code-blue) } /* string interpolation */
.highlight .sr { color: var(--code-purple) } /* regex */
.highlight .se { color: var(--code-purple) } /* escaped characters */

/* JS */
.highlight .nl { color: var(--code-cyan) } /* JSON key */
.language-json .highlight .s1, .language-json .highlight .s2 {
  color: var(--code-text); /* JSON value */
}
.highlight .kd { color: var(--code-red) } /* const, let, var */
.highlight .nx { color: var(--code-text) } /* variable names */
.language-js .highlight .na { color: var(--code-cyan) } /* object literal keys */
.language-js .highlight .na + .p { color: var(--code-cyan) } /* object literal key colon */
.highlight .dl { color: var(--code-green) } /* string quotes */

/* HASKELL */
.language-haskell .highlight .cd { color: var(--code-gray) } /* alternate (?) comment */

/* OTHER */
.highlight .na { color: var(--code-gray) } /* XML version= */
.highlight .nt { color: var(--code-green) } /* XML tag */
/* XML value */
.language-xml .highlight code { color: var(--code-text) }
.highlight .cm { color: var(--code-gray) } /* multi-line comment */
.highlight .cp { color: var(--code-red) } /* c++ preprocessor directives (e.g. #indclude) */
.highlight .mh { color: var(--code-green) } /* hex number */
/* .highlight .kt { color: #66d9ef } /* variable type */
.highlight .gu { color: var(--code-blue) } /* markdown heading */
.language-markdown .highlight code { color: var(--code-text) } /* markdown text */
 /* because I use cpp for AutoHotkey code, which occasionally produces syntax errors */
.language-cpp .highlight .err {
  color: var(--code-text);
  background-color: var(--code-background-color);
}
/* no language specified */
[class="highlighter-rouge"] code { color: var(--code-text) }

@media (prefers-color-scheme: light) {
  html:root {
    --code-gray: #7c7f84;
    --code-red: #d35463;
    --code-blue: #1b77c0;
    --code-green: #5a8442;
    --code-orange: #8c5f2c;
    --code-cyan: #419aad;
  }
}